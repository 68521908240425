import React from 'react'
import { graphql } from 'gatsby'
import PageTypes from '../components/page-types/_PageTypes'
import Blocks from '../components/blocks/_Blocks'

const StoryblokDefaultTemplate = ({ data, pageContext }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const getLocale = (str) => {
    const arr = str.split('/')
    return arr[0]
  }

  // console.log(node.name)

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  //   console.log("Storyblok Content", content)
  return React.createElement(
    PageTypes(content.component),
    {
      key: node.uid,
      content: content,
      name: node.name,
      type: content.component,
      locale: getLocale(node.full_slug),
      full_slug: node.full_slug,
      parentId: pageContext.parentId,
      alternates: node.alternates,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query StoryblokDefaultTemplateQuery($id: String!) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
      alternates {
        full_slug
      }
    }
  }
`

export default StoryblokDefaultTemplate
